import styled from "styled-components"

import { Skeleton } from "@material-ui/lab"

import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

const height = 50

export function ResponseServiceLoading() {
  return (
    <>
      <Box height={10} width={30}>
        <StyledSkeleton variant="rect" height={40} />
      </Box>
      <Box height={20} width={60}>
        <StyledSkeleton variant="rect" height={20} />
        <StyledSkeleton variant="rect" height={20} />
      </Box>
      <Box height={height}>
        <StyledSkeleton variant="rect" height={height} />
        <StyledSkeleton variant="rect" height={height} />
        <StyledSkeleton variant="rect" height={height} />
      </Box>
    </>
  )
}

const Box = styled.div<{ height?: number; width?: number }>`
  width: ${({ width }) => width || 100}%;
  margin-top: ${spacing.L};
  > * {
    margin-bottom: ${({ height }) => height}px;
  }
`

const StyledSkeleton = styled(Skeleton)`
  background: ${backgroundGrayV2};
`
